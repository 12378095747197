import "./App.css";
import Bio from "./Bio";
import Footer from "./Footer";
import Grid from "@mui/material/Grid";
import Video from "./Video"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs ={12} md={8}>
            <Video />
          </Grid>
          <Grid item xs={12} md={4}>
            <Bio />
            <Footer />
          </Grid>
        </Grid>
      </header>
    </div>
  );
}

export default App;
