import Box from "@mui/material/Box";
import "./Video.css";

function Video() {
  return (
    <Box sx={{ display: { xs: "none", md: "block" } }}>
      <video autoPlay muted loop id="myVideo" className="no-border" poster="first_frame.png">
        <source src="video.mp4" type="video/mp4" />
        <img src="first_frame.png" alt="Video Loading..." />
      </video>
      <div className="video-overlay" />
    </Box>
  );
}

export default Video;
