import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function Footer() {
  return (
    <Fade
      in={true}
      style={{ transitionDelay: "1500ms" }}
      {...{ timeout: 2000 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        px={{ xs: 10 }} 
        pt={{ xs: 5 }} 
      >
        <Divider width="75%" sx={{ bgcolor: "#EDEDF4" }} />
        <Box p={{ xs: 2, md: 2 }}>
          <Typography variant="caption" color="#CDCDCD">
            © 2024 Duc Thinh Nguyen
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}

export default Footer;
