import React, { useState, useEffect, useRef } from "react";
import ParticleImage, { forces, Vector } from "react-particle-image";
import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";

function Particles() {
  // init
  const parentRef = useRef(null);
  const [width, setWidth] = useState(350);
  const [height] = useState(350);

  // rgb to hex converter
  const rgbToHex = (r, g, b) =>
    "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");

  // handle window resize:
  // window.addEventListener("resize", () => {
  //   if (parentRef.current) {
  //     setWidth(parentRef.current.offsetWidth);
  //     setHeight(parentRef.current.offsetHeight);
  //   }
  // });

  const particleOptions = {
    filter: ({ x, y, image }) => {
      const pixel = image.get(x, y);
      const greyValue = 1;
      return pixel.r > greyValue && pixel.g > greyValue && pixel.b > greyValue;
    },
    color: ({ x, y, image }) => {
      const pixel = image.get(x, y);
      return rgbToHex(pixel.r, pixel.g, pixel.b);
    },
    radius: () => 1.2,
    mass: () => 50,
    friction: () => 0.27,
    initialVelocity: () =>
      new Vector((Math.random() - 0.5) * 100, (Math.random() - 1) * 100),
  };

  const motionForce = (x, y) => {
    return forces.disturbance(x, y, 25);
  };

  // get parent size
  useEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.offsetWidth);
    }
  }, [parentRef]);

  return (
    <Box ref={parentRef}>
      <ParticleImage
        width={width}
        height={height}
        src={process.env.PUBLIC_URL + "/pic2.png"}
        scale={0.27}
        entropy={10}
        maxParticles={12000}
        mouseMoveForce={motionForce}
        touchMoveForce={motionForce}
        particleOptions={particleOptions}
        backgroundColor="undefined"
      />
    </Box>
  );
}

export default Particles;
